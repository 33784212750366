<template>
  <div class="checkout">
    <v-container class="header-container">
      <category-block
        :target="category"
        :container="false"
        position="position1"
        class="category-block category-block-1"
      />
      <CategoryTitle
        :category="category"
        :showOnMobile="true"
        :showDescription="true"
        :addContainer="false"
      />

      <div class="cart-info-container">
        <!-- check if disclaimer is accepted -->
        <CheckoutCompleteProfile
          class="mb-5"
          v-if="!cart.user.profile.confirmed"
        />

        <ResponseMessage :response="response" />

        <CartInfoAddressCard
          :shippingAddress="cart.shippingAddress"
          :editable="editableAddress"
          @edit="openAddressSelector"
          class="w-100 mb-1"
        />
        <CartInfoTimeslotCard
          :shippingAddress="cart.shippingAddress"
          :timeslot="cart.timeslot"
          :editable="editableTimeslot"
          @edit="openTimeslotSelector"
          class="w-100 cart-info-timeslot-card"
          :value="timeslot"
        />

        <div class="gross-total-row text-right mt-0">
          <span class="text-h4">{{ $t("checkout.summary.total") }}:</span>
          <span class="text-h3">
            <strong class="gross-total">
              {{ $n(cart.grossTotal, "currency") }}
            </strong>
          </span>
        </div>

        <v-btn
          color="primary"
          class="text-uppercase"
          large
          depressed
          block
          :disabled="cart.totalItems == 0"
          :loading="loading"
          @click="goToCheckout"
        >
          <span>{{ $t("checkout.goToPaymentBtn") }}</span>
        </v-btn>
      </div>
    </v-container>
    <div class="cart-item-list-container">
      <CartItemList
        id="checkout-items-list"
        class="checkout-items-list"
        :full="true"
      />
      <v-alert
        v-if="error"
        type="error"
        border="left"
        transition="v-fab-transition"
      >
        {{ error }}
      </v-alert>
      <div class="checkout-note py-3">{{ $t("checkout.note") }}</div>
    </div>

    <v-container
      class="checkout-actions d-flex justify-space-between align-center flex-column flex-md-row"
    >
      <!-- <CheckoutSummary :orderCart="cart" @removeGiftCode="removeGift" /> -->

      <div
        class="d-flex flex-column flex-md-row mb-2 mb-md-0 w-100 w-sm-auto justify-space-between justify-md-start"
      >
        <v-btn
          color="primary"
          class="text-uppercase"
          large
          text
          depressed
          @click="emptyCartConfirm"
        >
          <span>{{ $t("checkout.emptyCartBtn") }}</span>
        </v-btn>
      </div>
      <div
        class="d-flex flex-column flex-md-row justify-space-between justify-md-end w-100 w-sm-auto"
      >
        <v-btn
          color="primary"
          class="mr-0 mr-md-2 mb-2 mb-md-0 text-uppercase"
          large
          depressed
          outlined
          @click="addAllToFavorites"
        >
          <span>{{ $t("checkout.addToListBtn") }}</span>
        </v-btn>
        <v-btn
          color="primary"
          class="text-uppercase"
          large
          depressed
          :disabled="profileLevel < minProfileLevel || cart.totalItems == 0"
          :loading="loading"
          @click="goToCheckout"
        >
          <span>{{ $t("checkout.goToPaymentBtn") }}</span>
        </v-btn>
      </div>
      <category-block
        :target="category"
        :container="false"
        position="position2"
        class="category-block category-block-2"
      />
      <category-block
        :target="category"
        :container="false"
        position="position3"
        class="category-block category-block-3"
      />
      <category-block
        :target="category"
        :container="false"
        position="position4"
        class="category-block category-block-4"
      />
    </v-container>
  </div>
</template>
<style lang="scss">
.checkout {
  .cart-info-timeslot-card {
    .value {
      text-transform: uppercase;
    }
  }

  .qty-wrap {
    max-width: 160px;
  }
  .checkout-item-list {
    background: transparent;
    .v-subheader {
      background: transparent;
    }
  }
  .v-list {
    padding: 0;
  }
  .checkout-note {
    border-top: 8px solid $white;
  }
  .accept-replacement {
    //display: grid;
    //grid-template-columns: 1fr 4fr;
    align-items: center;
    column-gap: 10px;
    margin: 0px 8px;
    padding: 10px 5px;
    border: 2px solid $primary;
    background-color: var(--v-grey-lighten3);
    border-radius: 4px;
    p {
      font-size: 14px;
      font-weight: 600;
    }
    .v-input--checkbox .v-label {
      font-weight: normal;
    }
    .v-input--selection-controls {
      margin-top: 0px;
    }
  }
}
</style>
<script>
// import CheckoutSummary from "@/components/cart/CheckoutSummary.vue";
import CheckoutCompleteProfile from "@/components/cart/CheckoutCompleteProfile.vue";
import CartInfoAddressCard from "@/components/cart/CartInfoAddressCard.vue";
import CartInfoTimeslotCard from "@/components/cart/CartInfoTimeslotCard.vue";
import CategoryTitle from "@/components/category/CategoryTitle.vue";
import ListSelector from "@/components/lists/ListSelector.vue";
import CartItemList from "@/components/cart/CartItemList.vue";
import ResponseMessage from "@/components/ResponseMessage.vue";
import GiftProductSelector from "@/components/gift/GiftProductSelector.vue";

import login from "~/mixins/login";
import cartInfo from "~/mixins/cartInfo";
import categoryMixins from "~/mixins/category";

import AnalyticsService from "~/service/analyticsService";
import ListService from "~/service/listService";
import RegistrationService from "~/service/userService";
import { forEachCartItem } from "~/service/ebsn.js";
import analyticsService from "~/service/analyticsService";

import { mapActions, mapState, mapGetters } from "vuex";
import get from "lodash/get";

export default {
  name: "Checkout",
  mixins: [login, cartInfo, categoryMixins],
  components: {
    CategoryTitle,
    // CheckoutSummary,
    CheckoutCompleteProfile,
    CartInfoAddressCard,
    CartInfoTimeslotCard,
    CartItemList,
    ResponseMessage
  },
  data() {
    return {
      error: null,
      loading: false,
      response: {},
      giftCertificates: [],
      emailKey: 1,
      packages: true,
      selectedOptions: {},
      iosMobile: false,
      showPersonalCoupon: false,
      dialogInstance: null,
      minProfileLevel: global.config.minProfileLevel
    };
  },
  computed: {
    ...mapState({
      cart: ({ cart }) => cart.cart
    }),
    ...mapGetters({
      profileLevel: "cart/profileLevel"
    }),
    editableAddress() {
      return !(this.cart.cartStatusId == 7);
    },
    editableTimeslot() {
      return !(this.$route.name == "Payment");
    },
    giftCertificatesReceived() {
      return this.cart.user?.giftCertificatesReceived;
    }
  },

  methods: {
    get: get,
    ...mapActions({
      emptyCart: "cart/emptyCart",
      loadCart: "cart/loadCart",
      getCart: "cart/getCart"
    }),
    sendActivationMail() {
      this.response = {};
      let _this = this;
      RegistrationService.registrationResendEmail(this.cart.user.email).then(
        function(data) {
          _this.response = data.response;
        },
        function(response) {
          _this.response = response;
        }
      );
    },
    async addAllToFavorites() {
      let _this = this;
      let res = await _this.$dialog.show(ListSelector, {
        waitForResult: true,
        fullscreen: _this.$vuetify.breakpoint.smAndDown,
        width: 300
      });
      if (res) {
        let items = [];
        forEachCartItem(_this.cart, function(item) {
          items.push(item);
        });
        await ListService.addProductsToList(res.listId, items);
      }
    },
    async emptyCartConfirm() {
      if (this.cart.totalItems > 0) {
        let res = await global.vm.$dialog.confirm({
          text: global.vm.$t("message.emptyCart")
        });
        if (res) {
          await this.emptyCart();
          AnalyticsService.cartEvent("emptyCart", this.cart.cartItems);
        }
      }
    },
    async goToCheckout() {
      let _this = this;
      _this.loading = true;
      try {
        if (await this.needLogin("addtocart")) {
          if (await this.needAddress()) {
            if (await this.needTimeslot()) {
              _this.$router.push({
                name: "Checkout"
              });
            }
          }
        }
      } catch (err) {
        await this.getCart();
        await this.needTimeslot();
        _this.error = err.message;
      } finally {
        _this.loading = false;
      }
    },
    async showPersonalCouponDialog() {
      this.dialogInstance = await this.$dialog.show(GiftProductSelector, {
        title: "Scegli ii proodtti a cui appliicare i tuoi bolloni",
        waitForResult: false,
        fullscreen: this.$vuetify.breakpoint.smAndDown
      });
      const result = await this.dialogInstance.wait();
      if (result) {
        await this.loadCart();
      }
      return result;
    },
    checkOsx() {
      var userAgent = [];
      var version = [];
      var versionNumber = 0;
      if (navigator.userAgent.indexOf("iPhone") > -1) {
        userAgent = navigator.userAgent.split(" ");
        userAgent.filter(ua => {
          if (ua.indexOf("Version/") > -1) {
            version = ua.split("/");
          }
        });
        versionNumber = parseFloat(version[1]);
        if (version[0] == "Version") {
          if (versionNumber < 15) {
            this.iosMobile = true;
          } else {
            this.iosMobile = false;
          }
        } else {
          this.iosMobile = false;
        }
      }
    },
    async removeGift(gift) {
      await this.removeGiftCode(gift);
      this.loadGiftCertificates();
    },
    handleView(entries, observer, isIntersecting) {
      if (isIntersecting) {
        analyticsService.viewProducts(this.products, this.getName());
      }
    },

    handleClick(payload) {
      analyticsService.clickProduct(
        payload.product,
        this.getName(),
        payload.index
      );
    }
  },
  async mounted() {
    // if (!this.cart.user.profile.confirmed) {
    //   this.sendActivationMail();
    // }
    global.EventBus.$on("resetAlertMessage", () => {
      if (this.error) {
        this.error = false;
      }
    });
    AnalyticsService.viewProducts(this.cart.cartItems, this.itemListName, 0);
    // AnalyticsService.beginCheckout(this.cart);
    AnalyticsService.viewCart(this.cart);
    this.checkOsx();
  }
};
</script>
